
import React from 'react';

import CheckTable from 'views/admin/dataTables/components/CheckTable';
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import { columnsDataCheck } from 'views/admin/default/variables/columnsData';

const Blockuser = () => {
 
  return (
  
    <div style={{marginTop:"130px"}}>
<CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> 
    </div>
  );
};

export default Blockuser;
