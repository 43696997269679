import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Adduser() {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    password: '',
    username: '',
    checked: false,
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if any field is empty
    if (Object.values(formData).some((field) => field === '')) {
      toast.warning('Please fill in all fields.');
      return;
    }
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('full_name', formData.full_name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('password', formData.password);
      formDataToSend.append('username', formData.username);
      formDataToSend.append('CASE', 'CREATE_USER');
  
      console.log('Data being sent to the server:', formDataToSend);
  
      const response = await axios.post(
        'https://limspakistan.com/monitor/apps/api.php',
        formDataToSend,
        {}
      );
  
      console.log('Response from the server:', response);
  
      if (response.status === 200) {
        console.log('User added successfully!');
        toast.success('User added successfully!');
  
        // Reset the form data to its initial state
        setFormData({
          full_name: '',
          email: '',
          password: '',
          username: '',
          checked: false,
        });
      } else {
        console.error('Error adding user');
        toast.error('Error adding user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  
  

  return (
    <Container>
      <Card style={{ maxWidth: "700px", marginTop: '100px' }}>
        <Card.Body>
          <Form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
           <Row>
              <Col xl={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Enter Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Form.Group className="mb-3" >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Form.Group className="mb-3" controlId="formBasicUsername">
                  <Form.Label>Enter Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </Container>
  );
}

export default Adduser;
