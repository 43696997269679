import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { AiFillFolderAdd , AiOutlineDelete } from "react-icons/ai";
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import columnsDataColumns from "components/users/Activeuser";
import Blockuser from "components/users/Blockuser";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import Adduser from "components/users/Adduser";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
 
  {
    name: " Users",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
   
  },
  {
    name: " Active user",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/Activeuser",
    component: columnsDataColumns,
   
  },

  {
    name: "Block user",
    layout: "/admin",
    path: "/Blockuser",
    icon: <Icon as={AiFillFolderAdd} width='20px' height='20px' color='inherit' />,
    component: Blockuser,
  },
  {
    name: " Add User",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/Adduser",
    component: Adduser,
   
  },

 
  {
    name: "Add farm",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={AiFillFolderAdd} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "delete farm",
    layout: "/admin",
    icon: <Icon as={AiOutlineDelete} width='20px' height='20px' color='inherit' />,
    path: "/defalt",
    component: DataTables,
  },
  {
    name: "total farm",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/defaut",
    component: DataTables,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  }
];

export default routes;
