/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { Loader } from 'rsuite';
import { ToastContainer, toast } from 'react-toastify';
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useEffect, useMemo, useState } from "react";
import AlertDialogSlide from '../components/Conformation'
import { Modal, Button, Form } from 'react-bootstrap';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
import Pagination from 'react-bootstrap/Pagination';

let active = 1;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}


export default function CheckTable(props) {
  const { columnsData, tableData } = props;
  const [myData, setMyData] = useState([]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(11);
  const [totalPages, setTotalPages] = useState(3);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage, pageSize]);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
const correctPassword = 'lims@123';

// Function to handle the password submission
const handlePasswordSubmit = () => {
  if (password === correctPassword) {
    toast('User deleted successfully!');
    setShow(false);
    setTimeout(() => {
      window.location.reload(); 
    }, 7000);
  } else {
    toast.error(' Incorrect password', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
};


const paginationItems = [];
for (let number = 1; number <= 4; number++) {
  paginationItems.push(
    <Pagination.Item
      key={number}
      active={number === active}
      onClick={() => handlePageChange(number)}
    >
      {number}
    </Pagination.Item>,
  );
}

  const handleUserSubmit = (data) => {
    setUserData(data);
  };
 
  useEffect(() => {
    
    
    getData();

  }, []); // Empty dependency array to run the effect only once on mount

  async function getData() {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('CASE', 'GET_ALL_USERS');
  
      const response = await axios.post(
        'https://limspakistan.com/monitor/apps/dev_api.php',
        formDataToSend,
        {}
      );
  
      console.log('Response from the server:', response);
  
      if (response.status === 200) {
        // Assuming the server response is an array of data
        console.log(response.data)
        setMyData(response.data);
      } else {
        // Handle error
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const tableInstance = useTable(
    {
      columns,
      data: myData, // Use the updated data for the current page
      initialState: { pageIndex: currentPage - 1, pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  return (

    <> 
     <Loader />  <Modal
    show={show}
    onHide={() => setShow(false)}
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-custom-modal-styling-title">
        Conformation
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <p>Are you sure you want to delete permanently?</p>

      <Form>
        <Form.Group controlId="formPassword">
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" style={{marginTop:"10px"}} onClick={handlePasswordSubmit}>
          Submit
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Block user
        </Text>
        <Menu />
      </Flex>
       
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
  {myData.map((item, index) => (
    item.status === 0 && (
      <Tr key={index}>
        <Td>{item.member_id}</Td>
        <Td>{item.username}</Td>
        <Td>{item.email}</Td>
        <Td>{item.full_name}</Td>
        <Td>
          <Button variant="success">Active</Button>
        </Td>
        <Td>
          {/* <Button variant="danger" onClick={() => setShow(true)}>
            Delete
          </Button> */}
        </Td>
        {/* Add more table cells as needed based on your data structure */}
      </Tr>
    )
  ))}
</Tbody>

      </Table>
      <ToastContainer />
      <Pagination style={{ marginLeft: '500px' }}>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
     
    </Card>
    
    </>
  );
}
