
import React from 'react';

import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import ColumnsTable from 'views/admin/dataTables/components/ColumnsTable';
import { columnsDataColumns } from 'views/admin/dataTables/variables/columnsData';
const Activeuser = () => {
 
  return (
  
    <div style={{marginTop:"130px"}}>
 <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />
    </div>
  );
};

export default Activeuser;
