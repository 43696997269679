export const columnsDataCheck = [
  {
    Header: "MEMBER_ID"
  },
  {
    Header: "USERNAME"
  },
  {
    Header: "EMAIL"
  },
  {
    Header: "FULL_NAME",
  },
  {
    Header: "STATUS"
  },
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
